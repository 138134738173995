import React, {ReactNode, useCallback, useState} from "react";

interface ErrorBoundaryProps {
    children: ReactNode; 
    errorComponent: ReactNode;
}

interface ErrorBoundaryState {
    hasError: boolean;
}

export const useCrash = () => {
    const [, setState] = useState();
    return useCallback((error: any) => setState(() => { throw error; }), [] );
}

export class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
    constructor(props: ErrorBoundaryProps) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error: Error) {
        return { hasError: true };
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        console.error(error);
    }

    render() {
        if (this.state.hasError) {
            return this.props.errorComponent;
        }

        return this.props.children;
    }
}
