import { useEffect, useState } from "react";

const injectedConfiguration = (window as any).roadside;
const environmentConfiguration = process.env;

const mergedConfiguration = {
    ...environmentConfiguration,
    ...injectedConfiguration,
} as { [key: string]: any };

interface Configuration {
    roadsideApi: {
        baseUrl: string;
    };
    qas: {
        url: string;
        token: string;
        dataset: {
            postal: "PAF" | "GNAF" | "DataFusion";
            residential: "PAF" | "GNAF" | "DataFusion";
        };
    };
    recaptcha: {
        siteKey: string;
    };
    idleTimoutInSeconds: number;
}

const createConfig = (merged: { [p: string]: any }) => {
    // Validate that we have our expected ENV variables defined!
    // Should always match environment variables read just below
    [
        "REACT_APP_ROADSIDE_API_BASE_URL",
        "REACT_APP_ROADSIDE_QAS_URL",
        "REACT_APP_ROADSIDE_QAS_TOKEN",
        "REACT_APP_ROADSIDE_QAS_DATASET_POSTAL",
        "REACT_APP_ROADSIDE_QAS_DATASET_RESIDENTIAL",
        "REACT_APP_ROADSIDE_RECAPTCHA_SITE_KEY",
        "REACT_APP_ROADSIDE_IDLE_TIMEOUT_IN_SECONDS",
    ].forEach((variable) => {
        if (!merged[variable]) {
            throw new Error(`Environment is missing variable ${variable}`);
        }
    });

    return {
        roadsideApi: {
            baseUrl: merged["REACT_APP_ROADSIDE_API_BASE_URL"],
        },
        qas: {
            url: merged["REACT_APP_ROADSIDE_QAS_URL"],
            token: merged["REACT_APP_ROADSIDE_QAS_TOKEN"],
            dataset: {
                postal: merged["REACT_APP_ROADSIDE_QAS_DATASET_POSTAL"],
                residential: merged["REACT_APP_ROADSIDE_QAS_DATASET_RESIDENTIAL"],
            },
        },
        recaptcha: {
            siteKey: merged["REACT_APP_ROADSIDE_RECAPTCHA_SITE_KEY"],
        },
        idleTimoutInSeconds: merged["REACT_APP_ROADSIDE_IDLE_TIMEOUT_IN_SECONDS"],
    };
};

const appConfiguration: Configuration = createConfig(mergedConfiguration);

const isEqual = (a: any, b: any): boolean => {
    let equal = true;
    Object.keys(a)
        .concat(Object.keys(b))
        .forEach((k) => {
            if (a[k] !== b[k]) {
                equal = false;
                return;
            }
        });
    return equal;
};

export const useConfiguration = (): Configuration => {
    const [state, setState] = useState({ ...(window as any).roadside });

    useEffect(() => {
        const timer = setInterval(() => {
            if (!isEqual((window as any).roadside, state)) {
                setState({ ...(window as any).roadside });
            }
        }, 1000);

        return () => {
            clearTimeout(timer);
        };
    }, [state]);

    const merged = {
        ...environmentConfiguration,
        ...state,
    } as { [key: string]: any };

    return createConfig(merged);
};

export default appConfiguration;
