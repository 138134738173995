import { Box, Typography, Link, useTheme } from "@mui/material";
import React from "react";
import { faLock, faChevronLeft } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const Footer: React.VFC = () => {
    const theme = useTheme();
    return (
        <Box
            sx={{
                width: "100%",
                height: 102,
                boxSizing: "border-box",
                display: "flex",
                padding: "25px",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                borderTop: "2px solid #ddd",
                [theme.breakpoints.up("lg")]: {
                    flexDirection: "row-reverse",
                    justifyContent: "space-between",
                },
            }}
        >
            <Box>
                <Typography>
                    {" "}
                    <FontAwesomeIcon
                        icon={faLock}
                        style={{
                            marginRight: 5,
                            height: 16,
                        }}
                    />{" "}
                    This is a secure site
                </Typography>
            </Box>
            <Box>
                <Typography>
                    {" "}
                    <FontAwesomeIcon
                        icon={faChevronLeft}
                        style={{
                            marginRight: 5,
                            height: 16,
                        }}
                    />{" "}
                    <Link sx={{ textDecoration: "none" }} href="https://www.ract.com.au/">
                        Back to RACT
                    </Link>
                </Typography>
            </Box>
        </Box>
    );
};
