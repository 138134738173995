// Called template until we're sure if this is a page template or the entire app template
// IE. Is this template directly used from App.tsx only? and every page exists within a router from App.tsx and looks like it? If that's the case then it's an AppTemplate or Template
// It's a PageTemplate when it's individually used by every Page, this is useful when we need more per page customization
// Delete this comment later

import { Box, Link, Typography } from "@mui/material";
import React from "react";
import { Footer } from "./Footer";
import { Header, HeaderProps } from "./Header";
import { ResponsiveAll } from "../ThemeWrapper";
import { ErrorBoundary } from "components/ErrorBoundary/ErrorBoundary";
import { InfoCard } from "components/InfoCard";

interface TemplateProps extends HeaderProps {
    title: string;
}

export const Template: React.FC<TemplateProps> = ({ children, headerImage, title }) => {
    return (
        <Box
            sx={{
                pl: ResponsiveAll("25px", "25px", "50px", "50px", "75px"),
                pr: ResponsiveAll("25px", "25px", "50px", "50px", "75px"),
                maxWidth: ResponsiveAll("none", "none", "none", "none", "1366px"),
                margin: "auto",
            }}
        >
            <Header headerImage={headerImage} />
            <Box
                sx={{
                    minHeight: "calc(100vh - 160px)",
                    maxWidth: "908px",
                    margin: "auto",
                    pt: ResponsiveAll("25px", "25px", "50px", "50px", "100px"),
                }}
            >
                {/* This should not be swapping between h1 and h2 as in the prototype between desktop and mobile
                In the ThemeWrapper h1 is made responsive then, to switch fontSize and lineHeight for mobile
            */}
                <Typography
                    variant="h1"
                    sx={{
                        textAlign: "center",
                        fontWeight: 500,
                    }}
                >
                    {title}
                </Typography>
                <Box
                    sx={{
                        pt: 3,
                    }}
                >
                    {children && (
                        <ErrorBoundary
                            errorComponent={
                                <Box sx={{ mt: 4 }}>
                                    <InfoCard variant={"error"}>
                                        <Typography
                                            variant={"h2"}
                                            sx={{ color: "ractRed", fontWeight: 500 }}
                                            gutterBottom
                                        >
                                            Apologies.
                                            <br />
                                            Something has gone wrong.
                                        </Typography>
                                        <Typography variant={"body1"} gutterBottom>
                                            We’re unfortunately experiencing some technical issues.
                                        </Typography>
                                        <Typography variant={"body1"}>
                                            Please call us on <Link href={"tel:132722"}>13 27 22</Link> to complete your
                                            purchase.
                                        </Typography>
                                    </InfoCard>
                                </Box>
                            }
                        >
                            {children}
                        </ErrorBoundary>
                    )}
                </Box>
            </Box>

            <Footer />
        </Box>
    );
};
