import { GetProductsResponse, GetTitlesResponse, MembershipPurchaseRequest, MembershipPurchaseResponse } from "./Types";
import config from "../../config";

export const getProducts = async (): Promise<GetProductsResponse> => {
    const response = await fetch(`${config.roadsideApi.baseUrl}api/Products`);

    if (response.status !== 200) {
        throw new Error(await response.text());
    }

    return (await response.json()) as GetProductsResponse;
};

export const getTitles = async (): Promise<GetTitlesResponse> => {
    const response = await fetch(`${config.roadsideApi.baseUrl}api/Titles`);

    if (response.status !== 200) {
        throw new Error(await response.text());
    }

    return (await response.json()) as GetTitlesResponse;
};

export const postMembershipPurchase = async (
    request: MembershipPurchaseRequest
): Promise<MembershipPurchaseResponse> => {
    const response = await fetch(`${config.roadsideApi.baseUrl}api/MembershipPurchase`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(request),
    });

    if (response.status !== 200) {
        throw new Error(await response.text());
    }

    return (await response.json()) as MembershipPurchaseResponse;
};
