// TODO: Move reused types here, when reasonable?
// Types/Values should match with API response types where that makes sense
// Are these values/types similar accross Roadside/Lifestyle?
// Maybe separate/prefix them when that's not the case
// Can also consider generating types/api calls from swagger spec?

export enum PaymentFrequency {
    monthly = "Monthly",
    yearly = "Yearly",
}

export enum RoadsideProduct {
    advantage = "Advantage",
    ultimate = "Ultimate",
}

export enum LifestyleProduct {
    lifestyle = "Lifestyle",
}

export enum PaymentType {
    directDebit = "DirectDebit",
    payNow = "PayNow",
}

export enum CardType {
    visa = "VISA",
    mastercard = "MASTERCARD",
}

export enum Gender {
    male = "MALE",
    female = "FEMALE",
    unspecified = "UNSPECIFIED",
}

// Get rid of these two if the merge is never needed
export const Product = { ...RoadsideProduct, ...LifestyleProduct };

// Called TypeofProduct to get rid of the eslint/no-redeclare warning
export type TypeofProduct = RoadsideProduct | LifestyleProduct;

export type PaymentFrequencyAmount = {
    [key in PaymentFrequency]: number;
};

export type RoadsidePricing = {
    [key in RoadsideProduct]: PaymentFrequencyAmount;
};

export type LifestylePricing = {
    [key in LifestyleProduct]: PaymentFrequencyAmount;
};

export type Pricing = RoadsidePricing & LifestylePricing;

export const getProductName = (product: TypeofProduct): string => {
    switch (product) {
        case RoadsideProduct.advantage:
            return "Roadside Advantage";
        case RoadsideProduct.ultimate:
            return "Roadside Ultimate";
        case LifestyleProduct.lifestyle:
            return "Lifestyle";
    }
};
