import ThemeWrapper from "components/ThemeWrapper";
import React, { lazy, Suspense } from "react";
import { BrowserRouter as Router, Navigate, Route, Routes } from "react-router-dom";
import Template from "./components/Template";
import { isLifestyle } from "utils";

// TODO: Setup App Insights for React
// https://docs.microsoft.com/en-us/azure/azure-monitor/app/javascript-react-plugin

const LazyRoadsideHomePage = lazy(() => import("./pages/RoadsideHomePage"));
const LazyRoadsidePurchasePage = lazy(() => import("./pages/RoadsidePurchasePage"));
const LazyLifestylePurchasePage = lazy(() => import("./pages/LifestylePurchasePage"));

const App: React.VFC = () => {
    const lifestyle = isLifestyle();

    return (
        <ThemeWrapper>
            <Suspense fallback={<Template title={""}>{/*loading...*/}</Template>}>
                <Router>
                    <Routes>
                        <Route
                            index
                            element={
                                lifestyle ? (
                                    <Navigate to="join-lifestyle/information" />
                                ) : (
                                    <Navigate to="join-roadside" />
                                )
                            }
                        />
                        {!lifestyle && <Route path="join-roadside" element={<LazyRoadsideHomePage />} />}
                        {!lifestyle && <Route path="join-roadside/*" element={<LazyRoadsidePurchasePage />} />}
                        {lifestyle && <Route path="join-lifestyle/*" element={<LazyLifestylePurchasePage />} />}
                    </Routes>
                </Router>
            </Suspense>
        </ThemeWrapper>
    );
};

export default App;
